import { checkIsIntegerYear } from '../../../../../customValidations/checkIsIntegerYear';
import { checkIsDateValid } from '../../../../../customValidations/checkIsDateValid';

export const buildEntityInputFields = (entity, inputData) => {
  const firstParentEntityValue = entity.parentEntities && entity.parentEntities[0] && entity.parentEntities.length ? entity.parentEntities[0].parent_entity_id : '';
  const secondParentEntityValue = entity.parentEntities && entity.parentEntities.length === 2 ? entity.parentEntities[1].parent_entity_id : '';
  const isFirstParentSelected = firstParentEntityValue !== '';
  const isSecondParentSelected = secondParentEntityValue !== '';
  
  return [
    {
      name: 'name',
      label: 'Name',
      type: 'Input',
      value: entity.name,
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'Input',
      value: entity.description,
      muiProps: {
        multiline: true,
        rows: 3
      }
    },
    {
      name: 'entity_group_id',
      label: 'Group',
      type: 'Selector',
      options: inputData.entityGroups,
      value: inputData.entityGroups.length ? entity.entity_group_id : '',
      optionKeys: { name: 'name', value: 'id' },
    },
    {
      name: 'year_of_incorporation',
      label: 'Year Of Incorporation',
      type: 'Year',
      required: false,
      value: entity.year_of_incorporation,
      validationOptions: {
        customValidate: checkIsIntegerYear,
      },
    },
    {
      name: 'entity_type',
      label: 'Entity Type',
      type: 'Input',
      value: entity.entity_type,
    },
    {
      name: 'code',
      label: 'Code',
      type: 'Input',
      value: entity.code,
      required: true,
    },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'AutocompleteSelect',
      options: inputData.countryJurisdictions,
      value: inputData.countryJurisdictions.length ? entity.jurisdiction.jurisdiction_id : '',
      optionKeys: { name: 'name', value: 'jurisdiction_id' },
      onChange: inputData.handleJurisdictionChange,
      required: true,
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'Input',
      value: inputData.currencyName,
      shouldDisplay: inputData.currencyName !== '',
      muiProps: {
        disabled: true
      }
    },
    {
      name: 'fiscal_year_start',
      label: 'Fiscal Year Start',
      type: 'Date',
      value: inputData.fiscalYearStart,
      required: true,
      validationOptions: {
        customValidate: checkIsDateValid,
      },
    },
    {
      name: 'fiscal_year_end',
      label: 'Fiscal Year End',
      type: 'Date',
      value: inputData.fiscalYearEnd,
      required: true,
      validationOptions: {
        customValidate: checkIsDateValid,
      },
    },
    {
      name: 'parentEntities-0',
      label: 'First Parent Entity',
      type: 'Selector',
      options: inputData.firstParentAvailableEntities,
      value: firstParentEntityValue,
      optionKeys: { name: 'name', value: 'entity_id' },
      onChange: inputData.handleParentEntityChange
    },
    {
      name: 'ownershipFirstParent',
      label: 'Ownership (%) 1st Parent',
      type: 'Input',
      value: entity.parentEntities && entity.parentEntities[0] && entity.parentEntities.length && entity.parentEntities[0].ownership ? entity.parentEntities[0].ownership : '',
      shouldDisplay: isFirstParentSelected,
      required: isFirstParentSelected,
      onChange: (event) => inputData.handleParentEntityPercentageChange(event, 'parentEntities-0')
    },
    {
      name: 'parentEntities-1',
      label: 'Second Parent Entity',
      type: 'Selector',
      options: inputData.secondParentAvailableEntities,
      value: secondParentEntityValue,
      optionKeys: { name: 'name', value: 'entity_id' },
      onChange: inputData.handleParentEntityChange
    },
    {
      name: 'ownershipSecondParent',
      label: 'Ownership (%) 2nd Parent',
      type: 'Input',
      value: entity.parentEntities && entity.parentEntities.length === 2 && entity.parentEntities[1].ownership ? entity.parentEntities[1].ownership : '',
      shouldDisplay: isSecondParentSelected,
      required: isSecondParentSelected,
      onChange: (event) => inputData.handleParentEntityPercentageChange(event, 'parentEntities-1')
    },
    {
      name: 'ein',
      label: 'EIN',
      type: 'Input',
      muiProps: {
        type: 'number'
      },
      value: entity.ein,
      required: inputData.isEINRequired,
    },
  ];
};
